import diplom from '../images/tinified/diplom.jpg';
import mesto from '../images/mesto.jpg';
import russia from '../images/tinified/russia.jpg';
import learn from '../images/tinified/learn.jpg';
import portfolio from '../images/tinified/portfolio.jpg';
import iBuy from '../images/i-BUY.png';
import githubSearch from '../images/githubSearch.png';
import quiz from '../images/quiz.png';
import apple from '../images/apple.png';
import mestoReact from '../images/mestoReact.png';
import weather from '../images/IBS-test.png';
import todoApp from '../images/todo-app.png';
import memoryGame from '../images/memory-game.png';
import middleCourse from '../images/middle-course.png';
import psyon from '../images/psyon.png';

const DATA = [
    {
        title: 'Psyon',
        description:
            'Коммерческий проект над которым я работал два года в составе команды, на позиции фронтенд разработчика. React, redux, redux saga, scss, webpack, formik, Janus',
        githubUrl: '',
        url: 'https://psyon.pro/',
        imgUrl: psyon,
        id: 26,
    },
    {
        title: 'Курс middle от ulbitv',
        description:
            'Проект результат пройденого курса повышения квалификации. React, typescript, redux toolkit, RTQ query, jest, FSD методология, storybook.',
        githubUrl: 'https://github.com/MKvaratshelia/middle-course',
        url: '',
        imgUrl: middleCourse,
        id: 25,
    },
    {
        title: 'Memory game',
        description: "'Memory' Игра на тренировку памяти. Create react app, react hooks, redux",
        githubUrl: 'https://github.com/MKvaratshelia/memory-game',
        url: 'https://mkvaratshelia.github.io/memory-game/',
        imgUrl: memoryGame,
        id: 12,
    },
    {
        title: 'Todo app',
        description:
            "'Todo app' учебное приложение на React. Create react app, классовые компоненты",
        githubUrl: 'https://github.com/MKvaratshelia/todo-app-react',
        url: 'https://mkvaratshelia.github.io/todo-app-react/',
        imgUrl: todoApp,
        id: 1,
    },
    {
        title: 'Weather app',
        description:
            "'Weather app' приложение погоды. Create-react-app, react hooks, функциональные компоненты",
        githubUrl: 'https://github.com/MKvaratshelia/IBS-test',
        url: 'https://mkvaratshelia.github.io/IBS-test/',
        imgUrl: weather,
        id: 2,
    },
    {
        title: 'Mesto-React',
        description:
            "Проект 'Mesto'. Проект полностью переписан на React. Create-react-app, react hooks, функциональные компоненты",
        githubUrl: 'https://github.com/MKvaratshelia/mesto-react',
        url: 'https://mkvaratshelia.github.io/mesto-react/',
        imgUrl: mestoReact,
        id: 3,
    },
    {
        title: 'React-quiz',
        description:
            "Проект 'React-quiz'. Проект переписан и проанализирован из учебного курса по React. Create-react-app, firebase,",
        githubUrl: 'https://github.com/MKvaratshelia/react-quiz',
        url: 'https://quiz-react-ee865.firebaseapp.com/',
        imgUrl: quiz,
        id: 4,
    },
    {
        title: 'Дипломный проект',
        description:
            "Проект 'NewsAnalyzer'. Анализ новостей по ключевому слову. Вёрстка по макету из Figma, HTML5, CSS3, БЭМ, JS ES6, собран Webpack",
        githubUrl: 'https://github.com/MKvaratshelia/diploma',
        url: 'https://mkvaratshelia.github.io/diploma/',
        imgUrl: diplom,
        id: 5,
    },
    {
        title: "Сервис 'Mesto'",
        description:
            "Проект 'Место'. Добавление карточек с названием места. Частично вёрстка по макету из Figma, JS ES6, ООП, работа с сервером, собран Webpack",
        githubUrl: 'https://github.com/MKvaratshelia/praktikum_mesto',
        url: 'https://mkvaratshelia.github.io/praktikum_mesto/',
        imgUrl: mesto,
        id: 6,
    },
    {
        title: "Лендинг 'Путешествия по России'",
        description: 'Адаптивный лендинг, вёрстка по макету из Figma, HTML5, CSS3, БЭМ',
        githubUrl: 'https://github.com/MKvaratshelia/russia',
        url: 'https://mkvaratshelia.github.io/russia/',
        imgUrl: russia,
        id: 7,
    },
    {
        title: "Лендинг 'Научиться учиться'",
        description: 'вёрстка по макету из Figma, HTML5, CSS3, БЭМ, анимация',
        githubUrl: 'https://github.com/MKvaratshelia/learn',
        url: 'https://mkvaratshelia.github.io/learn/',
        imgUrl: learn,
        id: 8,
    },
    {
        title: 'Портфолио с учебными проектами с Яндекс.Практикум',
        description: 'Create react app, SCSS, БЭМ',
        githubUrl: 'https://github.com/MKvaratshelia/portfolio',
        url: 'https://mkvaratshelia.github.io/portfolio/',
        imgUrl: portfolio,
        id: 9,
    },
    {
        title: 'Тестовое задание',
        description: 'Адаптивная вёрстка, автоподгрузка картинок, HTML, CSS, JS',
        gitHubUrl: 'https://github.com/MKvaratshelia/iBUY-test',
        url: 'https://mkvaratshelia.github.io/iBUY-test/',
        imgUrl: iBuy,
        id: 10,
    },
    {
        title: "Учебный проект 'GitHub search",
        description:
            'Проект переписан и проанализирован из учебного материала по react hooks. Create react app, bootstrap, react hooks',
        githubUrl: 'https://github.com/MKvaratshelia/GitHub-search',
        url: 'https://mkvaratshelia.github.io/GitHub-search/',
        imgUrl: githubSearch,
        id: 11,
    },
    {
        title: 'Тестовое задание',
        description:
            'Тестовое задание для компании Wispo. Сверстать прототип карточки товара и сделать сортировку по цене в обе стороны',
        githubUrl: 'https://github.com/MKvaratshelia/Wispo-test',
        url: 'https://mkvaratshelia.github.io/Wispo-test/',
        imgUrl: apple,
        id: 12,
    },
];

export { DATA };
