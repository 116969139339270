import React from 'react';
import '../styles/About.scss';

export const About = () => {
    return (
        <section className='about'>
            <div className='about__container'>
                <div className='about__image'></div>
                <div className='about__text'>
                    <h2 className='about__title'>Об авторе</h2>
                    <p className='about__description'>
                        Здравствуйте! Меня зовут Кварацхелия Михаил. Я frontend-разработчик из
                        Санкт-Петербурга. Окончил курс «frontend разработчик» на платформе
                        Яндекс.Практикум. Изучил HTML, CSS, JavaScript, Webpack, а так же работу с
                        GitHub. После обучения попал на стажировку в веб студию, в составе команды
                        разрабатывали библиотеку компонентов на React.
                    </p>
                    <p className='about__description'>
                        В 2020г устроился в компанию Онтех на должность frontend разработчик и
                        проработал в компании 2 года. Во время работы над проектом приобрел опыт как
                        самостоятельной так и командной работы, решение задач разного уровня от
                        верстки по макетам из figma до написания сложной логики компонентов и
                        создания видео-кабинета. Последнее время повышал квалификацию проходя курс и
                        изучая новые для меня технологии (typescript, rtq query, redux toolkit,
                        storybook, jest, CI/CD, docker).
                    </p>
                </div>
            </div>
        </section>
    );
};
